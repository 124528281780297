import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import DashboardContent from "../content/DashboardContent";
import Footer from "../include/Footer";

function Home() {
    return(
        <>
        <body className="sb-nav-fixed">
            <Nav/>
            <div id="layoutSidenav">
                <Sidenav/>
                <div id="layoutSidenav_content">
                    <main>
                    <DashboardContent/>
                    </main>
                    <Footer/>
                </div>
            </div>
        </body>
        </>
    );
}

export default Home;