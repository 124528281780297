import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

function DashboardContent() {

    const boxfont={
        fontSize: '1.2rem',
        fontWeight: 700,
        textAlign: 'center'
    };

    const boxfont2={
        fontSize: '2rem',
        fontWeight: 700,
        textAlign: 'center'
    };

    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const thumbup={
        color: '#009127',
        cursor: 'pointer'
    };

    const thumbdown={
        color: '#c70000',
        cursor: 'pointer'
    };

    /* Dashboard Details */
    let [regcount, setRegcount]=useState(0);
    let [activecount, setActivecount]=useState(0);
    let [inactivecount, setInactivecount]=useState(0);
    let [studcount, setStudcount]=useState(0);
    let [batchcount, setBatchcount]=useState(0);
    let [subjectcount, setSubjectcount]=useState(0);
    let [livesetcount, setLivesetcount]=useState(0);
    let [practicesetcount, setPracticesetcount]=useState(0);
    let [totalqstcount, setTotalqstcount]=useState(0);

    let [dataarr, setDataarr]=useState([]);

    async function dashboard_details() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/dashboard");
        var data=resp.data;
        setRegcount(data.regcount);
        setActivecount(data.activecount);
        setInactivecount(data.inactivecount);
        setStudcount(data.studcount);
        setBatchcount(data.batchcount);
        setSubjectcount(data.subjectcount);
        setLivesetcount(data.livesetcount);
        setPracticesetcount(data.practicesetcount);
        setTotalqstcount(data.totalqstcount);

        setDataarr(data.row);
        
    }
    /* Dashboard Details */

    useEffect(()=>{
        dashboard_details();
    },[])
    return(

    <>
    <div className="container-fluid px-4">
        <h2 className="mt-3 mb-4">DASHBOARD</h2>

        <div className="row">
            <div className="col-xl-3 col-md-6">
                <div className="card bg-primary text-white mb-4">
                    <div className="card-body" style={boxfont}>NEW REGISTRATION</div>
                    {/* <div className="card-footer d-flex align-items-center justify-content-between"> */}
                    <div className="card-footer" style={boxfont2}>
                        {regcount}
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card bg-success text-white mb-4">
                    <div className="card-body" style={boxfont}>ACTIVE STUDENT</div>
                    <div className="card-footer" style={boxfont2}>
                        {activecount}
                    </div>
                </div>
            </div>
            {/* <div className="col-xl-3 col-md-6">
                <div className="card bg-danger text-white mb-4">
                    <div className="card-body" style={boxfont}>INACTIVE STUDENT</div>
                    <div className="card-footer" style={boxfont2}>
                        {inactivecount}
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card bg-warning text-white mb-4">
                    <div className="card-body" style={boxfont}>TOTAL STUDENT</div>
                    <div className="card-footer" style={boxfont2}>
                        {studcount}
                    </div>
                </div>
            </div> */}

            <div className="col-xl-3 col-md-6">
                <div className="card bg-secondary text-white mb-4">
                    <div className="card-body" style={boxfont}>TOTAL BATCH</div>
                    <div className="card-footer" style={boxfont2}>
                        {batchcount}
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card bg-dark text-white mb-4">
                    <div className="card-body" style={boxfont}>TOTAL SUBJECT</div>
                    <div className="card-footer" style={boxfont2}>
                        {subjectcount}
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card bg-maroon text-white mb-4">
                    <div className="card-body" style={boxfont}>TOTAL LIVE SET</div>
                    <div className="card-footer" style={boxfont2}>
                        {livesetcount}
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card bg-darkblue text-white mb-4">
                    <div className="card-body" style={boxfont}>TOTAL PRACTICE SET</div>
                    <div className="card-footer" style={boxfont2}>
                        {practicesetcount}
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card bg-danger text-white mb-4">
                    <div className="card-body" style={boxfont}>TOTAL QUESTION</div>
                    <div className="card-footer" style={boxfont2}>
                        {totalqstcount}
                    </div>
                </div>
            </div>


        </div>

        <div className="card mb-4">
            <div className="card-header" style={tableheadline}>
                <i className="fas fa-table me-1"></i>
                PENDING NEW REGISTRATION
            </div>
            <div className="card-body">
                
                <div className="table-responsive">
                    <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>City</th>
                                <th>MA</th>
                                <th>PGDT</th>
                                <th>Reg Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {/* <tfoot>
                            <tr>
                                <th>Name</th>
                                <th>Position</th>
                                <th>Office</th>
                                <th>Age</th>
                                <th>Start date</th>
                                <th>Salary</th>
                            </tr>
                        </tfoot> */}
                        <tbody>
                        {dataarr.map((e)=>
                            <tr key={e.id}>
                            <td>{e.name}</td>
                            <td>{e.phone}</td>
                            <td>{e.email}</td>
                            <td>{e.city}</td>
                            <td>{e.ma==1?'Completed':e.ma==2?'Pursuring':e.ma==3?'Others':''}<br/>{e.mapassingyr}</td>
                            <td>{e.pgdt==1?'Completed':e.pgdt==2?'Pursuring':e.pgdt==3?'Others':''}<br/>{e.pgdtpassingyr}</td>
                            <td>{dateFormat(e.created_at, "dd-mm-yyyy")}</td>
                            <td>{e.status==0? 'Pending':''}</td>
                            <td><button onClick={async ()=>{
                                    if (window.confirm("Do you want to active it?")) {
                                        var fd=new FormData();
                                        fd.append("uid",e.id);
                                        var resp=await axios.post("https://safalata.net/safalatalara/api/dashboarddataapprove",fd);
                                        var data=resp.data;
                                        console.log(data);
                                        dashboard_details();
                                    }
                                }} className="btn btn-default"><i className="fas fa-thumbs-up" style={thumbup}></i></button>
                                <button onClick={async ()=>{
                                    if(window.confirm("Do you want to delete it?")) {
                                        var fd=new FormData();
                                        fd.append("uid",e.id);
                                        var resp=await axios.post("https://safalata.net/safalatalara/api/dashboarddatadelete",fd);
                                        var data=resp.data;
                                        console.log(data);
                                        dashboard_details();
                                    }
                                }} className="btn btn-default"><i className="fas fa-thumbs-down" style={thumbdown}></i></button></td>
                            </tr>
                        )}
                                 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </>

    );
}

export default DashboardContent;