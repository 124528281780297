import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

// For Import QA Loader
import {data} from "../include/Loader";

import "../loader.css";
// For Import QA Loader

function ImportQAContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };


    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };

    let [sublistarr, setSublistarr]=useState([]);

    // let [loader,setLoader]=useState(true);

    async function getSubjectlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/managecourselist");
        var data=resp.data;
        setSublistarr(data);

    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [textalertcolor,setTextalertcolor]=useState("");

    let [file,setFile]=useState(null);
    let [isnewfile,setIsnewfile]=useState(true);
    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newsubjectname,setNewsubjectname]=useState("");

    // For Modal
    let [subjectname,setSubjectname]=useState("");
    //let [subjectid,setSubjectid]=useState("");
    let [subjectslno,setSubjectslno]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal

    let [courseslno,setCourseslno]=useState("");

    

    useEffect(()=>{
        getSubjectlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    IMPORT QUESTION (.XLS) FILE
                </div>
                <div className="card-body">
                    

                    <div className='container' style={batchbg}>
                        {isalert? <div className={textalertcolor}>{msg}</div>:''}
                        <div className="mt-4 form-group">
                            <div style={selectright}>
                                <select className="form-control" onChange={(ev)=>{
                                    setSubjectslno(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setIsnewfile(false);
                                    } else {
                                        setIsnewfile(true);
                                        setIsnewbtn(true);
                                        setFile(null);
                                    }
                                }}>
                                <option value="">---------SELECT SUBJECT---------</option>

                                {sublistarr.map((c)=>
                                <optgroup label={c.course_name} key={c.course_slno}>
                                    {c.sub.map((s)=>
                                    <option key={s.subject_slno} value={s.subject_slno}>&#8594; {s.subjectname}</option>
                                    )}
                                </optgroup>                                
                                )}

                                </select>
                            </div>
                        </div>
                        <div className="mt-4 form-group">
                            <input type="file" className="form-control" onChange={(ev)=>{
                                setFile(ev.target.files[0]);
                                if (ev.target.files[0]) {
                                    setIsnewbtn(false);
                                } else {
                                    setIsnewbtn(true);
                                }
                            }} disabled={isnewfile} accept=".xls,.csv"/>
                        </div>
                        <div className="mt-4 form-group">
                            <Link to="" onClick={()=>{
                                window.open("./sample.xls","_blank");
                            }}>Demo Download File</Link>
                        </div>
                        <div className="mt-4 form-group">
                            <span className="input-group-btn">
                                <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={async()=>{
                                    setIsOpen(true);
                                    openModal();

                                    var fd=new FormData();
                                    fd.append("subjectslno", subjectslno);
                                    fd.append("file", file);
                                    var resp=await axios.post("https://safalata.net/safalatalara/api/multipleimport",fd);
                                    var data=resp.data;
                                    if (data.msgcode==1) {
                                        setIsalert(true);
                                        setMsg(data.msg);
                                        setTextalertcolor(data.alertcolor);
                                        setSubjectslno("");
                                        setFile("");
                                        setIsnewbtn(true);
                                    }
                                    
                                    setIsOpen(false);
                                }}>IMPORT FILE</button>
                            </span>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}
                    {/* <div className="loaderBox">
                        <BallTriangle color="#00BFFF" height={80} width={80} />
                    </div> */}
                </div>
            </div>                        
            
            {/* <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("subjectslno",subjectslno);
                fd.append("subjectname",subjectname);
                var resp=await axios.post("https://safalata.net/safalatalara/api/subjectnameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getSubjectlist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Subject Name Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button> */}

        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default ImportQAContent;