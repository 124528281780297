import LoginContent from "../content/LoginContent";
import Footer from "../include/Footer";

function Home() {
    return(
        <>
        <body className="bg-primary">
            {/* <div id="layoutSidenav">
                <div id="layoutSidenav_content"> */}
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                    <LoginContent/>
                    </main>
                    {/* <Footer/> */}
                </div>
            </div>
        </body>
        </>
    );
}

export default Home;