import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

// import dateFormat from 'dateformat';

function SearchStudentPaymentContent() {

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setPaidlistarr([]);
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'small'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-large'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-large'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '100px'
    };

    const selectright={
        paddingRight: '10px'
    };

    // For Modal Check
    let [modalid,setModalid]=useState(1);
    // For Modal Check

    let [isnewbtn,setIsnewbtn]=useState(true);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");
    
    let [count,setCount]=useState(0);

    // let [pageno,setPageno]=useState("");
    let [studpaylistarr, setStudpaylistarr]=useState([]);
    let [loader,setLoader]=useState(false);
    

    let [searchmonthyear,setSearchmonthyear]=useState("");

    let [monthyeararr,setMonthyeararr]=useState([]);
    let [monthyear,setMonthyear]=useState("");
    let [remark,setRemark]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd HH:MM:ss");

    let [paidlistarr,setPaidlistarr]=useState([]);
    
    let [paymentmonthyeararr, setPaymentmonthyeararr]=useState([]);
    let [batchlistarr,setBatchlistarr]=useState([]);
    let [studlistarr, setStudlistarr]=useState([]);
    let [studbatchid,setStudbatchid]=useState("");
    let [paymentoption,setPaymentoption]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [enrollid2,setEnrollid2]=useState("");


    async function getPaymentmonthyearlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getpaymentmonthyearlist");
        var data=resp.data;
        setPaymentmonthyeararr(data);
    }

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);
    }


    useEffect(()=>{
        // getStudpaymentlist();
        getPaymentmonthyearlist();
        getBatchlist();
        // setEnrollid("0");
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            {/* <h2 className="mt-3 mb-4">STUDENT PAYMENT</h2> */}

            <div className='container' style={batchbg}>
                <div className='row'>
                    <div className="mt-4 col-4">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setSearchmonthyear(ev.target.value);
                                // if (ev.target.value.length>0) {
                                    
                                //     setStudlistarr([]);
                                //     setBatchlistarr([]);
                                //     getBatchlist();

                                // } else {
                                //     setStudlistarr([]);
                                //     setBatchlistarr([]);
                                //     getBatchlist();
                                // }

                                if (ev.target.value.length>0) {
                                    setLoader(true);

                                    var fd=new FormData();
                                    fd.append("searchmonthyear",ev.target.value);
                                    // fd.append("paymentoption",paymentoption);
                                    fd.append("studbatchid",studbatchid);
                                    fd.append("enrollid",enrollid);

                                    var resp=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentlist",fd);
                                    var data=resp.data;
                                    setStudpaylistarr(data.obj);

                                    console.log(data.obj);

                                    var resp2=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentcount",fd);
                                    var data2=resp2.data;
                                    setCount(data2);

                                    console.log(ev.target.value+' '+studbatchid+' '+enrollid);

                                    // console.log(data2+','+ev.target.value+','+studbatchid+','+enrollid);

                                    setLoader(false);
                                } else {
                                    setStudlistarr([]);
                                    setBatchlistarr([]);
                                    getBatchlist();
                                }
                                    
                                                                      
                            }}>
                            <option value="">---------SELECT PAYMENT MONTH YEAR---------</option>

                            {paymentmonthyeararr.map((le)=>
                            <option key={le.payment_month_id} value={le.payment_month}>{le.payment_month}</option>
                            )}

                            </select>
                        </div>
                    </div>

                    {/* <div className="mt-4 col-3">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setPaymentoption(ev.target.value);
                                if (ev.target.value.length>0 && searchmonthyear.length>0) {

                                    setLoader(true);
                                    var fd=new FormData();
                                    fd.append("searchmonthyear",searchmonthyear);
                                    fd.append("paymentoption",ev.target.value);
                                    fd.append("studbatchid",studbatchid);
                                    fd.append("enrollid",enrollid);

                                    var resp=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentlist",fd);
                                    var data=resp.data;
                                    setStudpaylistarr(data);

                                    var resp2=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentcount",fd);
                                    var data2=resp2.data;
                                    setCount(data2);

                                    setLoader(false);
                                    
                                    
                                } else {
                                    setStudlistarr([]);

                                }

                            }}>
                            <option value="">---------SELECT PAYMENT OPTION---------</option>
                            <option value='1'>Paid</option>                             
                            <option value='2'>Due but allowed</option>
                            <option value='3'>Exemption</option>
                            </select>
                        </div>
                    </div> */}

                    <div className="mt-4 col-4">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setStudbatchid(ev.target.value);
                                if (ev.target.value.length>0 && searchmonthyear.length>0) {

                                    var fd3=new FormData();
                                    fd3.append('batchslno',ev.target.value);
                                    var resp3=await axios.post("https://safalata.net/safalatalara/api/batchwisefetchlist",fd3);
                                    var data3=resp3.data;
                                    setStudlistarr(data3.obj);
                                    console.log(searchmonthyear+' '+ev.target.value+' '+enrollid);

                                    setLoader(true);
                                    var fd=new FormData();
                                    fd.append("searchmonthyear",searchmonthyear);
                                    // fd.append("paymentoption",paymentoption);
                                    fd.append("studbatchid",ev.target.value);
                                    fd.append("enrollid",enrollid);

                                    var resp=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentlist",fd);
                                    var data=resp.data;
                                    setStudpaylistarr(data.obj);
                                    // setCount(data.count);

                                    var resp2=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentcount",fd);
                                    var data2=resp2.data;
                                    setCount(data2);

                                    setLoader(false);
                                    
                                    
                                    // setStudscorelistarr([]);
                                    // setStudname("");
                                    // setStudenrollid("");
                                    // setStudbatchname("");
                                    // setStudmonthyear("");
                                    // setIspdfbtn(true);
                                } else {
                                    setStudlistarr([]);

                                    // setStudscorelistarr([]);
                                    // setStudname("");
                                    // setStudenrollid("");
                                    // setStudbatchname("");
                                    // setStudmonthyear("");
                                    // setIspdfbtn(true);
                                }

                            }}>
                            <option value="">---------SELECT BATCH---------</option>

                            {batchlistarr.map((e)=>
                                <option key={e.batch_slno} value={e.batch_slno}>{e.batch_name}</option>                             
                            )}

                            </select>
                        </div>
                    </div>
                    <div className="mt-4 col-4">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setEnrollid(ev.target.value);
                                console.log(ev.target.value.length);
                                if (ev.target.value.length>0 && studbatchid.length>0 && searchmonthyear.length>0) {
                                    setLoader(true);

                                    var fd=new FormData();
                                    fd.append("searchmonthyear",searchmonthyear);
                                    // fd.append("paymentoption",paymentoption);
                                    fd.append("studbatchid",studbatchid);
                                    fd.append("enrollid",ev.target.value);

                                    var resp=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentlist",fd);
                                    var data=resp.data;
                                    setStudpaylistarr(data.obj);

                                    setLoader(false);
                                } else {
                                    setLoader(true);

                                    var fd=new FormData();
                                    fd.append("searchmonthyear",searchmonthyear);
                                    // fd.append("paymentoption",paymentoption);
                                    fd.append("studbatchid",studbatchid);
                                    fd.append("enrollid",ev.target.value);

                                    var resp=await axios.post("https://safalata.net/safalatalara/api/searchstudpaymentlist",fd);
                                    var data=resp.data;
                                    setStudpaylistarr(data.obj);

                                    setLoader(false);
                                }                                       
                            }}>
                            <option value="">---------SELECT STUDENT ID---------</option>

                            {studlistarr.map((s)=>
                            <option key={s.enroll_id} value={s.enroll_id}>{s.enroll_id} - {s.name}</option>
                            )}

                            </select>
                        </div>
                    </div>
                    
                    
                </div>
                

            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    {/* REPORT STUDENT PAYMENT LIST :: [ TOTAL {count} ] */}
                    {/* REPORT STUDENT PAYMENT LIST :: TOTAL - {count} */}
                    REPORT STUDENT PAYMENT LIST
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod} className='text-center'>Identity</th>
                                    <th style={txtblod}>Name</th>
                                    <th style={txtblod}>Enroll ID</th>
                                    <th style={txtblod}>Batch</th>
                                    <th style={txtblod}>Order ID</th>
                                    <th style={txtblod}>Payment ID</th>
                                    <th style={txtblod}>Payment Date</th>
                                    <th style={txtblod}>End Payment Date</th>
                                    <th style={txtblod}>Paid List</th>
                                    {/* <th style={txtblod}>Unpaid List</th> */}
                                    {/* <th style={txtblod}>Remark</th> */}
                                    <th style={txtblod} className='text-center'>Status</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {studpaylistarr.map((e)=>
                                <tr key={e.payment_list_id}>
                                    <td className='text-center'><img src={"https://safalata.net/safalatalara/uploadpic/"+e.pic} style={picstyle}/></td>
                                    <td style={txtblod}>{e.name}</td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}>{e.enroll_id}</span>
                                    </td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}>{e.batch_name}</span>
                                    </td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}>{e.order_id}</span>
                                    </td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}>{e.payment_id}</span>
                                    </td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}>{e.payment_date}</span>
                                    </td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}>{e.end_payment_date}</span>
                                    </td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}>{e.payment_list}</span>
                                    </td>
                                    {/* <td>
                                    {e.unpaid.map((u)=>
                                        <span key={u.payment_month_id} style={{color: '#9c0003',fontWeight: 700}}>&#8226; {u.payment_month}<br/></span>
                                    )}
                                    </td> */}
                                    {/* <td>
                                    {e.paid.map((p)=>
                                        <span key={p.payment_list_id}>&#8226; {p.payment_remark}<br/></span>
                                    )}
                                    </td> */}
                                    {/* <td></td> */}
                                    <td className='text-center'>{e.status==1?'Active':'Inactive'}</td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            {/* <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div> */}

            

        </div>


        
        </>
    );

}

export default SearchStudentPaymentContent;