import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

// For Modal
import Modal from 'react-modal';
// For Modal

// For Import QA Loader
import {data} from "../include/Loader";

import "../loader.css";
// For Import QA Loader

function ExportQAContent() {

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // For Modal

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    

    const selectright={
        paddingRight: '10px'
    };

    let [liveexamlistarr, setLiveexamlistarr]=useState([]);
    let [liveexamid, setLiveexamid]=useState("");

    // let [loader,setLoader]=useState(true);

    async function getExportliveexamlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/exportliveexamlist");
        var data=resp.data;
        setLiveexamlistarr(data);
    }

    let [isnewbtn,setIsnewbtn]=useState(true);


    useEffect(()=>{
        getExportliveexamlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    EXPORT QUESTION ANSWER (.XLS) FILE
                </div>
                <div className="card-body">
                    
                <form method="post" action="https://safalata.net/safalatalara/exportxls/index.php">
                    <div className='container' style={batchbg}>
                        <div className="mt-4 form-group">
                            <div style={selectright}>
                                <select className="form-control" onChange={(ev)=>{
                                    setLiveexamid(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setIsnewbtn(false);
                                    } else {
                                        setIsnewbtn(true);
                                    }
                                }} name="liveexamid">
                                <option value="">---------SELECT ONE EXAM FROM LIST---------</option>

                                {liveexamlistarr.map((c)=>
                                    <option key={c.liveset_slno} value={c.liveset_slno}>&#8594; {c.liveset_name}</option>                           
                                )}

                                </select>
                            </div>
                        </div>
                        
                        {/* <div className="mt-4 form-group">
                            <Link to="" onClick={()=>{
                                window.open("./sample.xls","_blank");
                            }}>Demo Download File</Link>
                        </div> */}
                        <div className="mt-4 form-group">
                            <span className="input-group-btn">
                                {/* <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={async()=>{

                                    var fd=new FormData();
                                    fd.append("liveexamid", liveexamid);
                                    var resp=await axios.post("http://localhost/ADMINSAFALATA/safalata/api/exportxls/",fd);
                                    var data=resp.data;
                                }}>EXPORT (.xls) FILE</button> */}
                                <input type="submit" value="Export (.xls) File" className="btn btn-info" disabled={isnewbtn}/>
                            </span>
                        </div>
                    </div>
                </form>

                </div>
            </div>
        </div>
        

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}
                </div>
            </div>                        
        </Modal>
        {/* FOr Modal */}

        
        </>
    );

}

export default ExportQAContent;