import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

// import dateFormat from 'dateformat';

function StudentPaymentContent() {

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setPaidlistarr([]);
        setIsOpen(false);
        // setIsOpen(true);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'small'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-large'
    };

    const iconeditblue={
        color: '#3a00c2',
        cursor: 'pointer',
        fontSize: 'large'
    };

    const iconeditdisabled={
        color: '#009127',
        cursor: 'alias',
        fontSize: 'x-large'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-large'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '100px'
    };

    const selectright={
        paddingRight: '10px'
    };

    // For Modal Check
    let [modalid,setModalid]=useState(1);
    // For Modal Check

    let [isnewbtn,setIsnewbtn]=useState(true);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");
    
    let [count,setCount]=useState("");

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let [studpaylistarr, setStudpaylistarr]=useState([]);
    let [loader,setLoader]=useState(false);

    let [changestatus, setChangestatus]=useState("");

    let [batchlistarr,setBatchlistarr]=useState([]);
    let [studlistarr, setStudlistarr]=useState([]);
    let [search,setSearch]=useState("");
    // let [enrollid,setEnrollid]=useState("");
    
    //let [limit,setLimit]=useState(3);

    let limit = 50;

    async function getStudpaymentlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        fd.append("search",search);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
        var data=resp.data;
        setStudpaylistarr(data);

        var resp3=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
        var data3=resp3.data;
        setCount(data3);
        // setCount(data.count);

        var resp2=await axios.get("https://safalata.net/safalatalara/api/getstudcount");
        var data2=resp2.data;
        // setCount(data2);

        const total=data2;
        setPageCount(Math.ceil(total/limit));
        
        setLoader(false);
    }

    let [monthyeararr,setMonthyeararr]=useState([]);
    let [monthyear,setMonthyear]=useState("");
    let [remark,setRemark]=useState("");
    let [remarkoption,setRemarkoption]=useState(0);

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd HH:MM:ss");

    // async function getyearmonthlistbyenrollid() {
    //     var fd=new FormData();
    //     fd.append("enrollid",id);
    //     var resp=await axios.post("https://safalata.net/safalatalara/api/getmonthyeararrlist",fd);
    //     var data=resp.data;
    //     setMonthyeararr(data);
    // }

    let [paidlistarr,setPaidlistarr]=useState([]);

    async function paidlistbyenrollid() {
        var fd=new FormData();
        fd.append("enrollid",id);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getpaidlistbyenrollid",fd);
        var data=resp.data;
        setPaidlistarr(data);

        // console.log(data);
    }


    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        
        fd.append("limit",limit);
        fd.append("search",search);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
        var data=resp.data;
        setStudpaylistarr(data);

        var resp3=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
        var data3=resp3.data;
        setCount(data3);

        var resp2=await axios.get("https://safalata.net/safalatalara/api/getstudcount");
        var data2=resp2.data;
        // setCount(data2);

        setLoader(false);
    };

    
    async function getBatchlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);
    }


    useEffect(()=>{
        getStudpaymentlist();

        getBatchlist();
        // setPageno(1);
        // getBatchlist();
    },[])

    return(
        <>
        {/* <head><title>Student Payment</title></head> */}
        <div className="container-fluid px-4">
            
            {/* <h2 className="mt-3 mb-4">STUDENT PAYMENT</h2> */}

            {/* <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    <input type="text" className='form-control' onChange={ async (ev)=>{


                        if (ev.target.value.length>0) {
                            setSearch(ev.target.value);
                            setIsnewbtn(false);
                        } else if (ev.target.value=="") {
                            setIsnewbtn(true);
                            setLoader(true);
                            var fd=new FormData();
                            fd.append("pageno",pageno);
                            fd.append("search","");
                            fd.append("limit",limit);
                            var resp=await axios.post("https://safalata.net/safalatalara/api/getstudlist",fd);
                            var data=resp.data;
                            setStudlistarr(data.obj);
                            setCount(data.datacount);

                            const total=data.datacount;

                            setPageCount(Math.ceil(total/limit));
                            setLoader(false);
                        }
                    }} placeholder='Search Student Name OR Enroll ID'/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudlist}>SEARCH</button>
                    </span>
                </div>

            </div> */}
            <div className='container' style={batchbg}>
                <div className='row'>

                    <div className='container' style={batchbg}>
                        <div className="mt-4 input-group" style={div70}>
                            <input type="text" className='form-control' onChange={ async (ev)=>{
                                if (ev.target.value.length>0) {
                                    setSearch(ev.target.value);
                                    setIsnewbtn(false);
                                } else if (ev.target.value=="") {
                                    setIsnewbtn(true);
                                    setLoader(true);
                                    var fd=new FormData();
                                    fd.append("pageno",pageno);
                                    fd.append("search","");
                                    fd.append("limit",limit);
                                    var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
                                    var data=resp.data;
                                    setStudpaylistarr(data);

                                    var resp3=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
                                    var data3=resp3.data;
                                    setCount(data3);

                                    var resp2=await axios.get("https://safalata.net/safalatalara/api/getstudcount");
                                    var data2=resp2.data;
                                    // setCount(data2);

                                    const total=data2;
                                    setPageCount(Math.ceil(total/limit));
                                    
                                    setLoader(false);
                                }
                            }} placeholder='Search Student Name OR Enroll ID'/>
                            <span className="input-group-btn">
                                <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudpaymentlist}>SEARCH</button>
                            </span>
                        </div>

                    </div>
                    
                    
                </div>
                

            </div>
            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    {/* MANAGE STUDENT LIST FOR PAYMENT (TOTAL STUDENT : {count}) */}
                    MANAGE STUDENT LIST FOR PAYMENT
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod} className='text-center'>Identity</th>
                                    <th style={txtblod}>Name</th>
                                    <th style={txtblod}>Enroll ID &amp; Date<br/>Batch<br/>City<br/>Status</th>
                                    <th style={txtblod}>Paid Month</th>
                                    <th style={txtblod}>Rzr Pay ID</th>
                                    <th className='text-center' style={txtblod}>Payment Date</th>
                                    <th className='text-center' style={txtblod}>Valid Upto</th>
                                    {/* <th style={txtblod}>Remark</th> */}
                                    <th style={txtblod} className='text-center'>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {studpaylistarr.map((e)=>
                                <tr key={e.enroll_id} style={{fontSize: 'smaller'}}>
                                    <td className='text-center'><img src={"https://safalata.net/safalatalara/uploadpic/"+e.pic} style={picstyle}/></td>
                                    <td style={txtblod}>{e.name}</td>
                                    <td><span style={{color: '#383838',fontWeight: 700}}><span style={{fontSize: 'small'}}>Enroll ID: {e.enroll_id}</span><br/>Enroll Date: {dateFormat(e.enroll_date, "dd-mm-yyyy")}<br/>Batch: {e.batch_name}<br/>City: {e.city}<br/>
                                    {e.status==1?<span style={{fontSize: 'small', color: '#0e6b00',fontWeight: 700}}>Active</span>:<span style={{color: '#9c0003',fontWeight: 700}}>Inactive</span>}</span>
                                    </td>
                                    <td>
                                    {e.paid.map((p)=>
                                        <span key={p.payment_list_id} style={{color: '#3a00c2',fontWeight: 700}}>&#8226; {p.payment_list}<br/></span>
                                    )}
                                    </td>
                                    <td>
                                    {e.paid.map((p)=>
                                        <span key={p.payment_list_id} style={{color: '#3a00c2',fontWeight: 500, fontSize: 'smaller'}}>&#8226; {p.payment_id}<br/></span>
                                    )}
                                    {/* <Link to="" className='btn-default' onClick={async()=>{
                                        setModalid(2);
                                        openModal();
                                        setId(e.enroll_id);
                                        setName(e.name);
                                        setStatus(e.status);
                                        var fd=new FormData();
                                        fd.append("enrollid",e.enroll_id);
                                        var resp=await axios.post("https://safalata.net/safalatalara/api/getpaidlistbyenrollid",fd);
                                        var data=resp.data;
                                        setPaidlistarr(data);
                                        }}><i className="fas fa-eye" style={iconkey}></i>
                                    </Link> */}
                                    </td>
                                    <td className='text-center'>
                                    {e.paid.map((p)=>
                                        p.payment_date!=null?<span key={p.payment_list_id} style={{color: '#3a00c2',fontWeight: 700}}>{[dateFormat(p.payment_date, "dd-mm-yyyy HH:MM:ss")]}<br/></span>:''
                                        // [dateFormat(p.payment_date, "dd-mm-yyyy hh:mm:ss")]
                                    )}
                                    </td>
                                    <td className='text-center'>
                                    {e.paid.map((p)=>
                                        p.payment_date!=null?<span key={p.payment_list_id} style={{color: '#3a00c2',fontWeight: 700}}>{[dateFormat(p.end_payment_date, "dd-mm-yyyy HH:MM:ss")]}<br/></span>:''
                                        // [dateFormat(p.payment_date, "dd-mm-yyyy hh:mm:ss")]
                                    )}
                                    </td>
                                    {/* <td>
                                    {e.unpaid.map((u)=>                                        
                                        <span key={u.payment_month_id} style={{color: '#9c0003',fontWeight: 700}}>&#8226; {u.payment_month}<br/></span>
                                    )}
                                    </td> */}
                                    {/* <td>
                                    {e.paid.map((p)=> */}

                                        
                                        
                                        {/* <span key={p.payment_list_id}>&#8226; {p.payment_remark} <button className='btn btn-default' onClick={async ()=>{
                                            if(window.confirm("Confirm To Delete This Option and Re-payment Again?")) {
                                                var fd=new FormData();
                                                fd.append("payment_list_id",p.payment_list_id);
                                                var resp=await axios.post("https://safalata.net/safalatalara/api/deletepaymentremark",fd);
                                                var data=resp.data;
                                                getStudpaymentlist()
                                            }
                                        }}><i className='fa fa-times' style={{color: 'red'}}></i></button><br/></span>
                                    
                                     )}
                                     </td> */}
                                    {/* <td></td> */}
                                    <td className='text-center'>
                                        {/* <Link to="" className='btn-default' onClick={async ()=>{
                                        openModal();
                                        setModalid(1);
                                        setId(e.enroll_id);
                                        setName(e.name);
                                        setStatus(e.status);
                                        var fd=new FormData();
                                        fd.append("enrollid",e.enroll_id);
                                        var resp=await axios.post("https://safalata.net/safalatalara/api/getmonthyeararrlist",fd);
                                        var data=resp.data;
                                        setMonthyeararr(data);
                                        }}><i class="fas fa-money-bill-alt" style={iconedit}></i></Link> */}
                                        <Link to="" className='btn' onClick={()=>{
                                        openModal();
                                        setModalid(3);
                                        setId(e.id);
                                        setName(e.name);
                                        setStatus(e.status);
                                        }}><i className="fas  fa-thumbs-up" style={iconedit}></i>
                                        </Link>&nbsp;&nbsp;
                                        {e.status==2?
                                        <Link to="" className='btn' onClick={async ()=>{
                                            if(window.confirm("Student Name: "+e.name+"\nEnroll ID: "+e.enroll_id+"\nPaid But No Payment Id Created.\nPlease check payment id and Allow for Payment??")) {
                                                var oid = Math.random().toString(16).slice(2)
                                                var amount = 300;
                                                alert(amount);
                                                var fd=new FormData();
                                                fd.append("oid",oid);
                                                fd.append("amt",amount);
                                                fd.append("enrollid",e.enroll_id);
                                                alert(amount);
                                                var resp=await axios.post("https://safalata.net/safalatalara/api/setpaymentlist",fd);
                                                var data=resp.data;
                                                getStudpaymentlist();
                                            }
                                            }}><i class="fas fa-edit" style={iconeditblue}></i>
                                        </Link>
                                        :
                                        <button className='btn' disabled><i class="fas fa-edit"></i>
                                        </button>
                                        }
                                    
                                    </td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

            

        </div>


        {/* For Modal */}
        
        {modalid==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PAYMENT UPDATE:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME : {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS : {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                <select className="form-control form-select" onChange={(ev)=>{
                    setMonthyear(ev.target.value);
                    if (ev.target.value.length>0 && remark.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Payment Month----</option>
                    {monthyeararr.map((e)=>
                    <option key={e.payment_month_id} value={e.payment_month}>{e.payment_month}</option>
                    )}
                    
                
                </select>
            </div>

            <div className="form-group" style={formgroup}>
                <select className="form-control form-select" onChange={(ev)=>{
                    setRemark(ev.target.value);
                    if (ev.target.value.length>0 && monthyear.length>0) {
                        if (ev.target.value=="Paid") {
                            setRemarkoption(1);
                        } else if (ev.target.value=="Due but allowed") {
                            setRemarkoption(2);
                        } else if (ev.target.value=="Exemption") {
                            setRemarkoption(3);
                        } else {
                            setRemarkoption(0);
                        }
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Payment Remark----</option>
                    <option value="Paid">1. Paid</option>
                    <option value="Due but allowed">2. Due but allowed</option>
                    <option value="Exemption">3. Exemption</option>                
                </select>
            </div>

            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("enrollid",id);
                fd.append("monthyear",monthyear);
                fd.append("curdate",curdate);
                fd.append("remark",remark);
                fd.append("remarkoption",remarkoption); //ADDED
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudpaymentmonthyear",fd);
                var data=resp.data;
                setIsnewbtn(true);
                setRemark("");
                setMonthyear("");
                setMonthyeararr([]);
                setIsOpen(false);       
                getStudpaymentlist();
                       
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>

            <button onClick={closeModal} className="btn btn-primary" style={{marginLeft: 10}}>Close</button>
            </div>
            
        </Modal>
        :''}
        {modalid==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PAID LIST:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME : {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS : {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                PAID LIST : 
                {paidlistarr.map((a)=>
                    <p key={a.payment_list_id} style={{margin: '5px', color: '#01691d'}}>&bull; {a.payment_list} [{dateFormat(a.payment_date, "dd-mm-yyyy HH:MM TT")}]</p>
                )}
            </div>

            <div className="form-group" style={formgroup}>
            <button onClick={()=>{
                setIsOpen(false);       
            }} className="btn btn-primary">Close</button>
            </div>
            
        </Modal>
        :''}

        {modalid==3?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT STATUS:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS - {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                <select value={changestatus} className="form-control" onChange={(ev)=>{
                    setChangestatus(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Status----</option>
                    <option value="0">Pending</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("status",changestatus);
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);       
                getStudpaymentlist()         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}
        {/* FOr Modal */}
        </>
    );

}

export default StudentPaymentContent;