
import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function NewCourseContent() {

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };


    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [password,setPassword]=useState("");

    let [passworderr,setPassworderr]=useState("Minimum 6-character password required");
    let [ispassword,setIspassword]=useState(false);
       
    async function changepassword() {
        var flag=1;
        if (password.length<6) {
            setIspassword(true);
            flag=0;
        }
        if (flag==1) {
            var fd=new FormData();
            fd.append("adminname",localStorage.getItem("adminname"));
            fd.append("password",password);
            
            var resp=await axios.post("https://safalata.net/safalatalara/api/changeadminpassword",fd);
            var data=resp.data;

            setIspassword(false);
            setIsalert(true);

            setMsg("Password Change Successfully");

            setTimeout(()=>{
                localStorage.removeItem("admintokenID");
                localStorage.removeItem("adminname");
                window.location.href="/login";
            }, 1000);
        }
        
        
    }

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">Change Password</h2>

            <div className='container' style={batchbg}>

                    {isalert? <div className='text text-success text-center' style={{fontSize: 'large', fontWeight: 700}}>{msg}</div>:''}
                    <div className="mt-4 form-group" style={{fontSize: 'large', fontWeight: 700}}>
                    User Name : {localStorage.getItem("adminname")}
                    </div>
                    <div className="mt-4 form-group">
                    <input type="password" className="form-control" placeholder='Minimum 6-character password required' onChange={(ev)=>{
                        setPassword(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }
                    }}/>
                    </div>
                    {ispassword==true?<div className='text text-danger'>{passworderr}</div>:''}
                    <div className="mt-4 form-group text-center" style={{marginTop: 20}}>
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={changepassword}>Change Password</button>
                    </div>

            </div>

            
        </div>


        </>
    );

}

export default NewCourseContent;