//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function NewBatchContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    // const iconactive={
    //     color: '#01691d',
    //     cursor: 'pointer',
    //     fontSize: 'medium'
    // };

    // const iconinactive={
    //     color: '#990000',
    //     cursor: 'pointer',
    //     fontSize: 'medium'
    // };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [batchlistarr, setBatchlistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);

        setLoader(false);
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newbatchname,setNewbatchname]=useState("");

    // For Modal
    let [batchname,setBatchname]=useState("");
    let [batchslno,setBatchslno]=useState("");
    // For Modal
       
    async function addNewbatch() {
        var fd=new FormData();
        fd.append("newbatchname",newbatchname);
        var resp=await axios.post("https://safalata.net/safalatalara/api/addnewbatch",fd);
        var data=resp.data;
        if (data.addalert==1) {
            getBatchlist();
            setIsnewbtn(true);
            setNewbatchname("");
            setIsalert(false);
        } else if (data.addalert==2) {
            setIsalert(true);
            setMsg(data.msg);
        }
        
    }

    useEffect(()=>{
        getBatchlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">NEW BATCH</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 input-group">
                    <input type="text" className="form-control" placeholder='New Batch Name' onChange={(ev)=>{
                        setNewbatchname(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={newbatchname}/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={addNewbatch}>ADD NEW BATCH</button>
                    </span>
                </div>

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    BATCH LIST
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod}>Batch ID</th>
                                    <th style={txtblod}>Batch Name</th>
                                    <th className='text-center' style={txtblod}>Batch Start Date</th>
                                    <th className='text-center' style={txtblod}>Batch End Date</th>
                                    <th className='text-center' style={txtblod}>Batch Status</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {batchlistarr.map((e)=>
                                <tr key={e.batch_slno}>
                                <td style={txtblod}>{e.batch_slno}</td>
                                <td style={txtblod}>{e.batch_name}</td>
                                <td className='text-center' style={txtblod}>{!e.batch_start_date?<input type="button" className="btn btn-success" value="Start Batch" onClick={async()=>{
                                    if (window.confirm("Sure to start this batch?")) {
                                        var fd=new FormData();
                                        fd.append("batchslno",e.batch_slno);
                                        var resp=await axios.post("https://safalata.net/safalatalara/api/batchstart",fd);
                                        var data=resp.data;
                                        getBatchlist();
                                    }
                                }}/>:dateFormat(e.batch_start_date, "dd-mm-yyyy")}</td>
                                <td className='text-center' style={txtblod}>{!e.batch_end_date?<input type="button" className="btn btn-danger" value="End Batch" onClick={async()=>{
                                    if (window.confirm("Sure to end this batch?")) {
                                        var fd=new FormData();
                                        fd.append("batchslno",e.batch_slno);
                                        var resp=await axios.post("https://safalata.net/safalatalara/api/batchend",fd);
                                        var data=resp.data;
                                        getBatchlist();
                                    }
                                }}/>:dateFormat(e.batch_end_date, "dd-mm-yyyy")}</td>
                                {/* <td>{dateFormat(e.created_at, "dd-mm-yyyy")}</td> */}
                                {e.batch_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}

                                <td className='text-center'><button onClick={async ()=>{
                                        openModal();
                                        setBatchslno(e.batch_slno);
                                        setBatchname(e.batch_name);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                    {e.batch_status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive this batch?")) {
                                            var fd=new FormData();
                                            fd.append("batchslno",e.batch_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/batchactiveinactive",fd);
                                            var data=resp.data;
                                            getBatchlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active this batch?")) {
                                            var fd=new FormData();
                                            fd.append("batchslno",e.batch_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/batchactiveinactive",fd);
                                            var data=resp.data;
                                            getBatchlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    {/* <button onClick={async ()=>{
                                        if(window.confirm("Do you want to delete this batch?")) {
                                            var fd=new FormData();
                                            fd.append("batchslno",e.batch_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/batchdelete",fd);
                                            var data=resp.data;
                                            getBatchlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button> */}
                                    </td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                Change Batch Name:
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setBatchname(ev.target.value);
                }} value={batchname}/>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("batchslno",batchslno);
                fd.append("batchname",batchname);
                var resp=await axios.post("https://safalata.net/safalatalara/api/batchnameupdate",fd);
                var data=resp.data;
                setIsOpen(false);
                getBatchlist();                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default NewBatchContent;