import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function ManageCourseContent() {

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [courselistarr, setCourselistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    async function getCourselist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/managecourselist");
        var data=resp.data;
        setCourselistarr(data);

        setLoader(false);
    }


    useEffect(()=>{
        getCourselist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    MANAGE COURSE LIST
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Course ID</th>
                                    <th style={txtblod}>Course Name</th>
                                    <th style={txtblod}>Subject List</th>
                                    <th className='text-center' style={txtblod}>Course Status</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {courselistarr.map((e)=>
                                <tr key={e.course_slno}>
                                <td className='text-center' style={txtblod}>{e.course_slno}</td>
                                <td style={txtblod}>{e.course_name}</td>

                                <td style={txtblod}>
                                {e.sub.map((s)=>
                                // <span key={s.subject_slno}>{s.subjectname}</span> <br/>
                                <div key={s.subject_slno}><i className='fas fa-arrow-right'></i> {s.subjectname} (ID-{s.subject_slno})<br/></div> 
                                )}
                                </td>

                                {e.course_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>

        </>
    );

}

export default ManageCourseContent;