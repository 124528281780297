import {Link} from 'react-router-dom';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//import DateCountDown from 'react-date-countdown-timer';

import { useParams } from 'react-router-dom';

function LiveScoreViewContent() {


    

    const params=useParams();
    

    const txtblod={
        fontWeight: '300',
        fontSize: 'medium',
        padding: 0
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };

    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconpending={
        color: '#900000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [livescoresetname, setLivescoresetname]=useState("");
    let [livescoreviewarr, setLivescoreviewarr]=useState([]);
    let [countstud, setCountstud]=useState(0);
    let [scoredate, setScoredate]=useState("");
    let [totalmark,setTotalmark]=useState("");

    let [rank, setRank]=useState("");

    let [loader, setLoader]=useState(true);

    let i=0;
    let j=0;

    async function getLivescoreviewlist() {
        var fd=new FormData();
        fd.append("suid",params.suid);
        fd.append("livexmid",params.livexmid);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getlivescoreviewlist",fd);
        var data=resp.data;
        setLivescoresetname(data.livesetname);
        setLivescoreviewarr(data.obj2);
        setRank(data.rank);
        console.log(data.rank);
        setCountstud(data.studcount);
        setScoredate(data.studliveexamscore_date);
        setTotalmark(data.total_mark);

        setLoader(false);
    }
      
    useEffect(()=>{
        getLivescoreviewlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <div className="card mb-2 mt-2">
                <div className='row'>
                <div className='col-sm-3 col-12'>
                    <div style={{paddingLeft:10}}><img src="../../assets/img/logo.png" style={{width: '160px'}} /></div>
                </div>
                <div className='col-sm-9 col-12' style={{paddingTop:15}}>
                    <h2 style={{fontSize: '1.2em', fontWeight: 700, paddingLeft:10, color: '#047000'}}>SAFALATA - Shraddha Group of Education</h2>
                    <span style={{fontSize: '1.0em', fontWeight: 700, paddingLeft:10}}>Powered by cheapwebguru.com</span>
                    
                </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i> LIVE EXAM NAME : {livescoresetname}
                    <br/>
                    <i className="fas fa-table me-1"></i> TOTAL STUDENTS FOR THIS EXAM : {countstud}
                    <br/>
                    <i className="fas fa-table me-1"></i> LIVE SCORE DATE : {dateFormat(scoredate, "dd-mm-yyyy")}
                    <br/>
                    <i className="fas fa-table me-1"></i> TOTAL MARKS : {totalmark}
                    <br/>
                    <Link to="/liveexam-score"><i className="fas fa-angle-double-left me-1"></i> BACK</Link> {countstud>0? <Link to="" onClick={()=>window.print()} style={{textDecoration: 'none'}}> | DOWNLOAD</Link>:''}
                </div>
                <div className="card-body" style={{marginBottom: 30}}>
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={{fontSize: 'medium', width: '10%'}}>#Rank</th>
                                    <th style={{fontSize: 'medium', width: '35%'}}>Stud Name</th>
                                    <th className='text-center' style={{fontSize: 'medium', width: '15%'}}>Student ID</th>
                                    <th className='text-center' style={{fontSize: 'medium', width: '10%'}}>Batch</th>
                                    {/* <th className='text-center' style={txtblod}>Score Date</th> */}
                                    {/* <th className='text-center' style={{fontSize: 'medium', width: '15%'}}>Total Marks</th> */}
                                    <th className='text-center' style={{fontSize: 'medium', width: '15%'}}>Mark Obtained</th>
                                </tr>
                            </thead>
                        </table>

                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <tbody>

                            {livescoreviewarr.map((e)=>
                            rank[j].stud_liveset_score===e.stud_liveset_score?
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '10%', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{i=i+1}</td>
                                <td style={{fontSize: 'medium', width: '35%', fontWeight: '700', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{e.name}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{e.enroll_id}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '10%', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{e.batch_name}</td>
                                {/* <td className='text-center' style={txtblod}>{dateFormat(e.studliveexamscore_date, "dd-mm-yyyy")}<br/>{dateFormat(e.studliveexamscore_date, "HH:MM TT")}</td> */}
                                {/* <td className='text-center' style={{fontSize: 'medium', width: '15%'}}>{e.total_mark}</td> */}
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%', backgroundColor: 'rgba(66, 255, 41, 0.3)'}}>{e.stud_liveset_score}</td>
                                
                            </tr>
                            
                            : 
                            rank[j+1].stud_liveset_score===e.stud_liveset_score?
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '10%', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{i=i+1}</td>
                                <td style={{fontSize: 'medium', width: '35%', fontWeight: '700', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{e.name}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{e.enroll_id}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '10%', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{e.batch_name}</td>
                                {/* <td className='text-center' style={txtblod}>{dateFormat(e.studliveexamscore_date, "dd-mm-yyyy")}<br/>{dateFormat(e.studliveexamscore_date, "HH:MM TT")}</td> */}
                                {/* <td className='text-center' style={{fontSize: 'medium', width: '15%'}}>{e.total_mark}</td> */}
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%', backgroundColor: 'rgba(158, 3, 70, 0.3)'}}>{e.stud_liveset_score}</td>
                                
                            </tr>
                            :
                            rank[j+2].stud_liveset_score===e.stud_liveset_score?
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '10%', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{i=i+1}</td>
                                <td style={{fontSize: 'medium', width: '35%', fontWeight: '700', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{e.name}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{e.enroll_id}</td>
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '10%', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{e.batch_name}</td>
                                {/* <td className='text-center' style={txtblod}>{dateFormat(e.studliveexamscore_date, "dd-mm-yyyy")}<br/>{dateFormat(e.studliveexamscore_date, "HH:MM TT")}</td> */}
                                {/* <td className='text-center' style={{fontSize: 'medium', width: '15%'}}>{e.total_mark}</td> */}
                                <td className='text-center' style={{fontSize: 'medium', fontWeight: '700', width: '15%', backgroundColor: 'rgba(17, 17, 245, 0.3)'}}>{e.stud_liveset_score}</td>
                                
                            </tr>
                            :
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={{fontSize: 'medium', width: '10%'}}>{i=i+1}</td>
                                <td style={{fontSize: 'medium', width: '35%'}}>{e.name}</td>
                                <td className='text-center' style={{fontSize: 'medium', width: '15%'}}>{e.enroll_id}</td>
                                <td className='text-center' style={{fontSize: 'medium', width: '10%'}}>{e.batch_name}</td>
                                {/* <td className='text-center' style={txtblod}>{dateFormat(e.studliveexamscore_date, "dd-mm-yyyy")}<br/>{dateFormat(e.studliveexamscore_date, "HH:MM TT")}</td> */}
                                {/* <td className='text-center' style={{fontSize: 'medium', width: '15%'}}>{e.total_mark}</td> */}
                                <td className='text-center' style={{fontSize: 'medium', width: '15%'}}>{e.stud_liveset_score}</td>
                                
                            </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

        


        </div>

        </>
    );

}

export default LiveScoreViewContent;