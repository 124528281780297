//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function NewCourseContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    // const iconactive={
    //     color: '#01691d',
    //     cursor: 'pointer',
    //     fontSize: 'medium'
    // };

    // const iconinactive={
    //     color: '#990000',
    //     cursor: 'pointer',
    //     fontSize: 'medium'
    // };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [courselistarr, setCourselistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    async function getCourselist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newcourselist");
        var data=resp.data;
        setCourselistarr(data);

        setLoader(false);
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newcoursename,setNewcoursename]=useState("");

    // For Modal
    let [coursename,setCoursename]=useState("");
    let [courseid,setCourseid]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal
       
    async function addNewcourse() {
        var fd=new FormData();
        fd.append("newcoursename",newcoursename);
        var resp=await axios.post("https://safalata.net/safalatalara/api/addnewcourse",fd);
        var data=resp.data;
        if (data.addalert==1) {
            getCourselist();
            setIsnewbtn(true);
            setNewcoursename("");
            setIsalert(false);
        } else if (data.addalert==2) {
            setIsalert(true);
            setMsg(data.msg);
        }
        
    }

    useEffect(()=>{
        getCourselist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">NEW COURSE</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 input-group">
                    <input type="text" className="form-control" placeholder='New Course Name' onChange={(ev)=>{
                        setNewcoursename(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={newcoursename}/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={addNewcourse}>ADD NEW COURSE</button>
                    </span>
                </div>

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    COURSE LIST
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Course ID</th>
                                    <th style={txtblod}>Course Name</th>
                                    <th className='text-center' style={txtblod}>Created Date</th>
                                    <th className='text-center' style={txtblod}>Course Status</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {courselistarr.map((e)=>
                                <tr key={e.course_slno}>
                                <td className='text-center' style={txtblod}>{e.course_slno}</td>
                                <td style={txtblod}>{e.course_name}</td>
                                <td className='text-center' style={txtblod}>{dateFormat(e.batch_start_date, "dd-mm-yyyy")}</td>

                                {e.course_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}

                                <td className='text-center'><button onClick={async ()=>{
                                        openModal();
                                        setCourseid(e.course_slno);
                                        setCoursename(e.course_name);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                    {e.course_status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive this course?")) {
                                            var fd=new FormData();
                                            fd.append("courseid",e.course_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/courseactiveinactive",fd);
                                            var data=resp.data;
                                            getCourselist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active this course?")) {
                                            var fd=new FormData();
                                            fd.append("courseid",e.course_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/courseactiveinactive",fd);
                                            var data=resp.data;
                                            getCourselist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    <button onClick={async ()=>{
                                        if(window.confirm("Do you want to delete this course?")) {
                                            var fd=new FormData();
                                            fd.append("courseid",e.course_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/coursedelete",fd);
                                            var data=resp.data;
                                            getCourselist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button></td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::COURSE MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setCoursename(ev.target.value);
                }} value={coursename}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                alert(courseid+' '+coursename)
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("courseid",courseid);
                fd.append("coursename",coursename);
                var resp=await axios.post("https://safalata.net/safalatalara/api/coursenameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getCourselist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Course Name Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default NewCourseContent;