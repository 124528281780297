import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import ChangePasswordContent from "../content/ChangePasswordContent";
import Footer from "../include/Footer";

function ChangePassword() {
    return(
        <>
        <body className="sb-nav-fixed">
            <Nav/>
            <div id="layoutSidenav">
                <Sidenav/>
                <div id="layoutSidenav_content">
                    <main>
                    <ChangePasswordContent/>
                    </main>
                    <Footer/>
                </div>
            </div>
        </body>
        </>
    );
}

export default ChangePassword;