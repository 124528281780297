import React from 'react'
import ContentLoader from 'react-content-loader'
//import ContentLoader, { Facebook } from 'react-content-loader';

const TableLoaderSingleCircle = props => (
  <ContentLoader
    // width={1000}
    width='1100'
    height={550}
    viewBox="0 0 1100 550"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    {...props}
  >
    {/* <rect x="51" y="45" rx="3" ry="3" width="906" height="17" />
    <circle cx="879" cy="123" r="11" />
    <circle cx="914" cy="123" r="11" />
    <circle cx="950" cy="123" r="11" />
    <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
    <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
    <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="155" rx="3" ry="3" width="897" height="2" />
    <circle cx="880" cy="184" r="11" />
    <circle cx="915" cy="184" r="11" />
    <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
    <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
    <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
    <rect x="56" y="216" rx="3" ry="3" width="897" height="2" />
    <circle cx="881" cy="242" r="11" />
    <circle cx="916" cy="242" r="11" />
    <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="274" rx="3" ry="3" width="897" height="2" />
    <circle cx="882" cy="303" r="11" />
    <circle cx="917" cy="303" r="11" />
    <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
    <rect x="58" y="335" rx="3" ry="3" width="897" height="2" />
    <circle cx="881" cy="363" r="11" />
    <circle cx="916" cy="363" r="11" />
    <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="395" rx="3" ry="3" width="897" height="2" />
    <circle cx="882" cy="424" r="11" />
    <circle cx="917" cy="424" r="11" />
    <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="453" rx="3" ry="3" width="897" height="2" />
    <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
    <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
    <circle cx="882" cy="484" r="11" />
    <circle cx="917" cy="484" r="11" />
    <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="513" rx="3" ry="3" width="897" height="2" />
    <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
    <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
    <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
    <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
    <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
    <rect x="933" y="54" rx="3" ry="3" width="24" height="33" /> */}

    <rect x="51" y="25" rx="3" ry="3" width="925" height="17" />
    <circle cx="904" cy="103" r="11" />
    <rect x="104" y="95" rx="3" ry="3" width="141" height="15" />
    <rect x="305" y="94" rx="3" ry="3" width="299" height="15" />
    <rect x="661" y="94" rx="3" ry="3" width="141" height="15" />
    {/* <rect x="861" y="94" rx="3" ry="3" width="141" height="15" /> */}
    <rect x="55" y="135" rx="3" ry="3" width="921" height="2" />
    <circle cx="905" cy="164" r="11" />
    <rect x="105" y="156" rx="3" ry="3" width="141" height="15" />
    <rect x="306" y="155" rx="3" ry="3" width="299" height="15" />
    <rect x="662" y="155" rx="3" ry="3" width="141" height="15" />
    <rect x="56" y="196" rx="3" ry="3" width="920" height="2" />
    <circle cx="906" cy="222" r="11" />
    <rect x="106" y="214" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="213" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="213" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="254" rx="3" ry="3" width="919" height="2" />
    <circle cx="907" cy="283" r="11" />
    <rect x="107" y="275" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="274" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="274" rx="3" ry="3" width="141" height="15" />
    <rect x="58" y="315" rx="3" ry="3" width="918" height="2" />
    <circle cx="906" cy="343" r="11" />
    <rect x="106" y="335" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="334" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="334" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="375" rx="3" ry="3" width="919" height="2" />
    <circle cx="907" cy="404" r="11" />
    <rect x="107" y="396" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="395" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="395" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="433" rx="3" ry="3" width="921" height="2" />
    <rect x="51" y="29" rx="3" ry="3" width="2" height="465" />
    <rect x="976" y="29" rx="3" ry="3" width="2" height="465" />
    <circle cx="907" cy="464" r="11" />
    <rect x="107" y="456" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="455" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="455" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="493" rx="3" ry="3" width="921" height="2" />
    <rect x="52" y="60" rx="3" ry="3" width="924" height="17" />
    <rect x="53" y="37" rx="3" ry="3" width="68" height="33" />
    <rect x="222" y="34" rx="3" ry="3" width="149" height="33" />
    <rect x="544" y="35" rx="3" ry="3" width="137" height="33" />
    <rect x="782" y="36" rx="3" ry="3" width="72" height="33" />
    <rect x="952" y="34" rx="3" ry="3" width="24" height="33" />
  </ContentLoader>
)



export default TableLoaderSingleCircle