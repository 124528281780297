import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function NewSubjectContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    // const iconactive={
    //     color: '#01691d',
    //     cursor: 'pointer',
    //     fontSize: 'medium'
    // };

    // const iconinactive={
    //     color: '#990000',
    //     cursor: 'pointer',
    //     fontSize: 'medium'
    // };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };

    let [subjectlistarr, setSubjectlistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    async function getSubjectlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newsubjectlist");
        var data=resp.data;
        setSubjectlistarr(data);

        setLoader(false);
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newsubjectname,setNewsubjectname]=useState("");

    // For Modal
    let [subjectname,setSubjectname]=useState("");
    //let [subjectid,setSubjectid]=useState("");
    let [subjectslno,setSubjectslno]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal

    let [courseslno,setCourseslno]=useState("");

    let [courselistarr, setCourselistarr]=useState([]);
    async function getCourselist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newcourselist");
        var data=resp.data;
        setCourselistarr(data);

        setLoader(false);
    }
       
    async function addNewsubject() {
        var fd=new FormData();
        fd.append("newsubjectname",newsubjectname);
        fd.append("courseslno",courseslno);
        var resp=await axios.post("https://safalata.net/safalatalara/api/addnewsubject",fd);
        var data=resp.data;
        if (data.addalert==1) {
            getSubjectlist();
            setIsnewbtn(true);
            setNewsubjectname("");
            setCourseslno("");
            setIsalert(false);
        } else if (data.addalert==2) {
            setIsalert(true);
            setMsg(data.msg);
        }
    }

    useEffect(()=>{
        getSubjectlist();
        getCourselist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">NEW SUBJECT</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 input-group">
                    <div style={selectright}>
                        <select className="form-control" onChange={(ev)=>{
                        setCourseslno(ev.target.value);
                            if (ev.target.value.length>0 && newsubjectname.length>0) {
                                setIsnewbtn(false);
                                //addNewbatch();
                            } else {
                                setIsnewbtn(true);
                            }
                        }} value={courseslno}>
                        <option value="">---------SELECT COURSE---------</option>
                        {courselistarr.map((c)=>
                        <option key={c.course_slno} value={c.course_slno}>{c.course_name}</option>
                        )}                        
                        </select>
                    </div>
                    <input type="text" className="form-control" placeholder='New Subject Name' onChange={(ev)=>{
                        setNewsubjectname(ev.target.value);
                        if (ev.target.value.length>0 && courseslno.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={newsubjectname}/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={addNewsubject}>ADD NEW SUBJECT</button>
                    </span>
                </div>

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    SUBJECT LIST
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Subject ID</th>
                                    <th style={txtblod}>Subject Name</th>
                                    <th style={txtblod}>Course Name </th>
                                    <th className='text-center' style={txtblod}>Subject Status</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {subjectlistarr.map((e)=>
                                <tr key={e.subject_slno}>
                                <td className='text-center' style={txtblod}>{e.subject_slno}</td>
                                <td style={txtblod}>{e.subjectname} <Link to="" className='btn-default' onClick={async ()=>{
                                        openModal();
                                        //setModalid(1);
                                        setSubjectslno(e.subject_slno);
                                        setSubjectname(e.subjectname);
                                    }}><i className='fas fa-edit' style={iconedit}></i></Link></td>
                                <td style={txtblod}>{e.course_name}</td>
                                {e.subject_status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}

                                <td className='text-center'>
                                    {/* <button onClick={async ()=>{
                                        openModal();
                                        setSubjectslno(e.subject_slno);
                                        setSubjectname(e.subjectname);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button> */}

                                    {e.subject_status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive this subject?")) {
                                            var fd=new FormData();
                                            fd.append("subjectslno",e.subject_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/subjectactiveinactive",fd);
                                            var data=resp.data;
                                            getSubjectlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active this subject?")) {
                                            var fd=new FormData();
                                            fd.append("subjectslno",e.subject_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/subjectactiveinactive",fd);
                                            var data=resp.data;
                                            getSubjectlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    <button onClick={async ()=>{
                                        if(window.confirm("Do you want to delete this course?")) {
                                            var fd=new FormData();
                                            fd.append("subjectslno",e.subject_slno);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/subjectdelete",fd);
                                            var data=resp.data;
                                            getSubjectlist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button></td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::SUBJECT MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setSubjectname(ev.target.value);
                }} value={subjectname}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("subjectslno",subjectslno);
                fd.append("subjectname",subjectname);
                var resp=await axios.post("https://safalata.net/safalatalara/api/subjectnameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getSubjectlist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Subject Name Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default NewSubjectContent;