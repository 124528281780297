import Nav from "../include/Nav";
import Sidenav from "../include/Sidenav";
import Footer from "../include/Footer";

import ExportQAContent from "../content/ExportQAContent";

function ExportQA() {

    return(
    <>
    <body className="sb-nav-fixed">
        <Nav/>
        <div id="layoutSidenav">
            <Sidenav/>
            <div id="layoutSidenav_content">
                <main>
                <ExportQAContent/>
                </main>
                <Footer/>
            </div>
        </div>
    </body>
    </>
    );
}

export default ExportQA;