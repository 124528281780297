//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function ManageBatchContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [managebatcharr, setManagebatcharr]=useState([]);

    let [loader,setLoader]=useState(true);

    async function getManagebatchlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/managebatchlist");
        var data=resp.data;
        setManagebatcharr(data);

        setLoader(false);
    }


       
    useEffect(()=>{
        getManagebatchlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">MANAGE BATCH</h2>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    BATCH DETAILS
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>Batch ID</th>
                                    <th style={txtblod}>Batch Name</th>
                                    <th className='text-center' style={txtblod}>Total Student</th>
                                    <th className='text-center' style={txtblod}>Batch Status</th>
                                    <th className='text-center' style={txtblod}>Batch Lock/Unlock</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {managebatcharr.map((e)=>
                                <tr key={e.batchslno}>
                                    <td className='text-center' style={txtblod}>{e.batchslno}</td>
                                    <td style={txtblod}>{e.batchname}</td>
                                    <td className='text-center' style={txtblod}>{e.count}</td>
                                    {e.batchstatus==1?
                                    <td className='text-center' style={txtgreen}>ACTIVE</td>
                                    :<td className='text-center' style={txtred}>INACTIVE</td>}
                                    {e.batchlock==0?
                                    <td className='text-center' style={txtgreen}>UNLOCK</td>
                                    :<td className='text-center' style={txtred}>LOCK</td>}

                                    <td className='text-center'>
                                        {e.batchlock==0?<button onClick={async ()=>{
                                            if (window.confirm("Sure, LOCK This Batch?")) {
                                                var fd=new FormData();
                                                fd.append("batchslno",e.batchslno);
                                                var resp=await axios.post("https://safalata.net/safalatalara/api/batchlockunlock",fd);
                                                var data=resp.data;
                                                getManagebatchlist();
                                            }
                                        }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                        :
                                        <button onClick={async ()=>{
                                            if (window.confirm("Sure, UNLOCK This Batch?")) {
                                                var fd=new FormData();
                                                fd.append("batchslno",e.batchslno);
                                                var resp=await axios.post("https://safalata.net/safalatalara/api/batchlockunlock",fd);
                                                var data=resp.data;
                                                getManagebatchlist();
                                            }
                                        }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                        }
                                    </td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>

        </>
    );

}

export default ManageBatchContent;