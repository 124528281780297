//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
// import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function NoticeboardContent() {

    

    // For Modal

    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [noticelistarr, setNoticelistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    let [count, setCount]=useState("");

    async function getnoticeboardlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/noticeboardlist");
        var data=resp.data;
        setNoticelistarr(data.obj);
        setCount(data.count);
        setLoader(false);
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newnoticename,setNewnoticename]=useState("");
       
    async function addnoticebaord() {
        var fd=new FormData();
        fd.append("newnoticename",newnoticename);
        var resp=await axios.post("https://safalata.net/safalatalara/api/addnewnotice",fd);
        var data=resp.data;
        getnoticeboardlist();
        setNewnoticename("");
        setIsnewbtn(true);
    }

    useEffect(()=>{
        getnoticeboardlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">Notice Board</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 form-group">
                    <textarea className="form-control" rows={5} placeholder='Add Notice' onChange={(ev)=>{
                        setNewnoticename(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={newnoticename}/>
                </div>
                <div className="mt-4 form-group text-center">
                    <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={addnoticebaord}>ADD NOTICE</button>
                </div>
            </div>


                


            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    NOTICEBOARD LIST - TOTAL COUNT ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?

                        <div className='row'>
                            {noticelistarr.map((n)=>
                            <div className='col-md-4' style={{marginTop: 10, marginBottom: 10, border: '1px solid #999'}}>
                                <button className='btn btn-default' onClick={async ()=>{
                                    var fd=new FormData();
                                    fd.append("noticeboard_id",n.noticeboard_id);
                                    var resp=await axios.post("https://safalata.net/safalatalara/api/deletenotice",fd);
                                    var data=resp.data;
                                    getnoticeboardlist();
                                }} style={{float: 'right'}}><i className='fas fa-times' style={{color: '#ff0000'}}></i></button>
                              <span style={{margin: 0, paddingTop: 5, paddingBottom:5}}>{n.noticeboard_details}<br/>
                              {dateFormat(n.noticeboard_date_time, "mmmm dd, yyyy HH:MM")}</span>
                              
                            </div>
                            )}
                        </div>

                        
                :<TableLoader/>}
                </div>
            </div>
        </div>


        
        </>
    );

}

export default NoticeboardContent;