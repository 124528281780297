//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoaderSingleCircle from '../include/TableLoaderSingleCircle';
// For Facebook Loader

function BatchWiseCandidateContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const picstyle={
        width: '50px'
    };

    let [batchlistarr, setBatchlistarr]=useState([]);

    let [loader,setLoader]=useState(false);

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);
    }

    let [isnewbtn,setIsnewbtn]=useState(true);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");

    let [changestatus, setChangestatus]=useState("");

    let [batchslno, setBatchslno]=useState(0);
    let [fetchlistarr, setFatchlistarr]=useState([]);

    let [count,setCount]=useState("");

    async function batchwisefetchlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("batchslno",batchslno);
        var resp=await axios.post("https://safalata.net/safalatalara/api/batchwisefetchlist",fd);
        var data=resp.data;
        setCount(data.count);
        setLoader(false);
        if (data.addalert==1) {
            setFatchlistarr(data.obj);
        } else if (data.addalert==2) {
            setFatchlistarr([]);
        }
        
    }

    useEffect(()=>{
        getBatchlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">BATCH WISE CANDIDATE SEARCH</h2>

            <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    <select value={batchslno} className="form-control" onChange={(ev)=>{
                        setBatchslno(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }
                    }} >
                        <option value="">-------------------------Select Batch-------------------------</option>
                        {batchlistarr.map((e)=>
                            <option value={e.batch_slno} key={e.batch_slno}>{e.batch_name}</option>
                        )}
                    </select>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={batchwisefetchlist}>SEARCH CANDIDATES</button>
                    </span>
                </div>

            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    CANDIDATE LIST
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod}>Identity</th>
                                    <th style={txtblod}>Name</th>
                                    <th style={txtblod}>Enroll No</th>
                                    <th style={txtblod}>Batch</th>
                                    <th style={txtblod}>Phone</th>
                                    <th style={txtblod}>City</th>
                                    <th style={txtblod}>Status</th>
                                    <th style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {count>0?
                            fetchlistarr.map((e)=>
                                <tr key={e.id}>
                                    <td style={txtblod}><img src={"https://safalata.net/safalatalara/uploadpic/"+e.pic} style={picstyle}/></td>
                                    <td style={txtblod}>{e.name}</td>
                                    <td style={txtblod}>{e.enroll_id}</td>
                                    <td style={txtblod}>{e.batch_name}</td>
                                    <td style={txtblod}>{e.phone}</td>
                                    <td style={txtblod}>{e.city}</td>
                                    {e.status==0?<td className='text-center' style={txtgrey}>PENDING</td>:''}
                                    {e.status==1?<td className='text-center' style={txtgreen}>ACTIVE</td>:''}
                                    {e.status==2?<td className='text-center' style={txtred}>INACTIVE</td>:''}
                                    
                                    <td className='text-center'><button onClick={()=>{
                                        openModal();
                                        setId(e.id);
                                        setName(e.name);
                                        setStatus(e.status);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td style={txtblodcenter} colSpan={8}>No Record Found</td>
                            </tr>
                            }
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoaderSingleCircle/>}
                </div>
            </div>
        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                Change Candidate Status:
            </div>
            <div className="form-group" style={formgroup}>
                NAME : {name}
            </div>
            <div className="form-group" style={formgroup}>
                CURRENT STATUS : {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            <div className="form-group" style={formgroup}>
                <select value={changestatus} className="form-control" onChange={(ev)=>{
                    setChangestatus(ev.target.value);
                }}>
                    <option value="0">PENDING</option>
                    <option value="1">ACTIVE</option>
                    <option value="2">INACTIVE</option>
                </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("status",changestatus);
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);
                batchwisefetchlist();                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default BatchWiseCandidateContent;