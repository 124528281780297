import {Link} from 'react-router-dom';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//import DateCountDown from 'react-date-countdown-timer';

import { useParams } from 'react-router-dom';

function LiveScoreViewPrintContent() {

    const params=useParams();
    

    const txtblod={
        fontWeight: '300',
        fontSize: 'medium',
        padding: 0
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconpending={
        color: '#900000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'medium'
    };

    let [livescoresetname, setLivescoresetname]=useState("");
    let [livescoreviewarr, setLivescoreviewarr]=useState([]);
    let [countstud, setCountstud]=useState(0);
    let [scoredate, setScoredate]=useState("");

    let [loader,setLoader]=useState(true);

    let i=0;

    async function getLivescoreviewlist() {
        var fd=new FormData();
        fd.append("suid",params.suid);
        fd.append("livexmid",params.livexmid);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getlivescoreviewlist",fd);
        var data=resp.data;
        setLivescoresetname(data.livesetname);
        setLivescoreviewarr(data.obj2);
        setCountstud(data.studcount);
        setScoredate(data.studliveexamscore_date);

        setLoader(false);
    }
      
    useEffect(()=>{
        getLivescoreviewlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
{/*             
            <div className="card mb-4 mt-3">
                <div className='row'>
                <div className='col-sm-3'>
                    <img src="../../assets/img/logo.png" style={{width: 160}} />
                </div>
                <div className='col-sm-9' style={{paddingTop:10}}>
                    <h2 style={{fontSize: '1.6em', fontWeight: 700}}>SAFALATA - Shraddha Group of Education<br/>
                    Powered by cheapwebguru.com
                    </h2>
                </div>
                </div>
            </div> */}

            <div className="card">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i> LIVE EXAM NAME : {livescoresetname}
                    <br/>
                    <i className="fas fa-table me-1"></i> TOTAL STUDENTS FOR THIS EXAM : {countstud}
                    <br/>
                    <i className="fas fa-table me-1"></i> LIVE SCORE DATE : {dateFormat(scoredate, "dd-mm-yyyy")}
                    <br/>
                    <Link to="/liveexam-score"><i className="fas fa-angle-double-left me-1"></i> BACK</Link> {countstud>0? <Link to="" onClick={()=>window.print()} style={{textDecoration: 'none'}}> | DOWNLOAD</Link>:''}
                </div>
                <div className="card-body" style={{marginBottom: 30}}>
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>#Rank</th>
                                    <th style={txtblod}>Stud Name</th>
                                    <th className='text-center' style={txtblod}>Student ID</th>
                                    <th className='text-center' style={txtblod}>Batch</th>
                                    {/* <th className='text-center' style={txtblod}>Score Date</th> */}
                                    <th className='text-center' style={txtblod}>Total Marks</th>
                                    <th className='text-center' style={txtblod}>Mark Obtained</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livescoreviewarr.map((e)=>
                                
                            <tr key={e.liveset_slno}>
                                
                                <td className='text-center' style={txtblod}>{i=i+1}</td>
                                <td style={txtblod}>{e.name}</td>
                                <td className='text-center' style={txtblod}>{e.enroll_id}</td>
                                <td className='text-center' style={txtblod}>{e.batch_name}</td>
                                {/* <td className='text-center' style={txtblod}>{dateFormat(e.studliveexamscore_date, "dd-mm-yyyy")}<br/>{dateFormat(e.studliveexamscore_date, "HH:MM TT")}</td> */}
                                <td className='text-center' style={txtblod}>{e.total_mark}</td>
                                <td className='text-center' style={txtblue}>{e.stud_liveset_score}</td>
                                
                            </tr>
                                
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

        


        </div>

        </>
    );

}

export default LiveScoreViewPrintContent;